import { ALL_BOOKINGS } from "../actions/action-types";

const INITIAL_STATE = {
    token: null,
    Account_logged:'false',
    WHEELCHAIR_BOOKING : []
};

export const DashboardReducer = (state = INITIAL_STATE, action) => {
    let newState = Object.assign({}, state);
    if (action.type === ALL_BOOKINGS) {
        newState.WHEELCHAIR_BOOKING = action.payload;
        return newState;
    } 
    return state;
};
