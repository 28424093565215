import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { AssignBooking_toDeliveryGuy, GetAll_Delievery_Guys } from '../../common/apiFunction';
import { Layout, Radio, Space, Table, Tag } from 'antd';


const AssignModal = ({ callbackBtn, ...props }) => {
    const [modal2Open, setModal2Open] = useState(props?.visible);
    const[Assign_modal,setAssign_modal] = useState(false)
    const [AvailableDeleiveryGuys, SetAvailableDeleiveryGuys] = useState([])
    const [bottom, setBottom] = useState('bottomRight');



    useEffect(() => {
        setModal2Open(props?.visible)
        console.log(props.record)
    }, [props?.visible])

    useEffect(() => {
        GetAll_Delievery_Guys().then((data) => {
          console.log(data)
          SetAvailableDeleiveryGuys(data?.data)
        })
      }, [])

      const handleAssign  = ((record)=>{
        console.log(props.record)
        const bookingData = props?.record
        console.log(record)
        setModal2Open(false)
        callbackBtn(false)

        const payload ={
          "station_name":bookingData?.station_name,
          "bookingId": bookingData?.booking_id,
          "dilivery_id": record?.dilivery_id
        }
        AssignBooking_toDeliveryGuy(payload).then((data)=>{
          console.log(data)
          if(data?.data?.status == true)
          {
            alert("User assign successfully")
          }
        })
      })

      const AvailableUsercolumns = [
        {
          title: 'User',
          dataIndex: 'dilverypartner_name',
          key: 'dilverypartner_name',
        },
        {
          title: 'Status',
          key: 'status',
          render: (_, record) => (
            <Space size="middle">
              <a className="transparent-button" onClick={()=>{handleAssign(record)}}>Assign {record.name}</a>
              {/* <a>Delete</a> */}
            </Space>
          ),
          // render: (tags) => (
          //   <span>
          //     {tags.map((tag) => {
      
          //       let isChecked = tag === 'active';
          //       return (
          //         <Space direction="vertical">
          //           <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={isChecked} />
          //         </Space>
          //       );
          //     })}
          //   </span>
          // ),
        },
      ];
      
      
    return (
        <>

            <Modal
                title="User List"
                centered
                open={modal2Open}
                onOk={() => { setModal2Open(false); callbackBtn(false) }}
                onCancel={() => { setModal2Open(false); callbackBtn(false) }}
            >
                <Table className='Content_block' columns={AvailableUsercolumns} pagination={{ position: [bottom] }} dataSource={AvailableDeleiveryGuys} />
            </Modal>
        </>
    );
};
export default AssignModal;