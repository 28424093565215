import axios from "axios";
import { ApiDomain } from "../env";

const api = axios.create({
    baseURL: ApiDomain,
});


export const PostAPI = async (URL, Body) => {
    let Result = await new Promise((resolve, reject) => {
        axios({
            // Endpoint to send files
            url: ApiDomain + URL,
            method: "POST",

            headers: {
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
            data: Body,
        })

            // Handle the response from backend here
            .then((res) => {
                return resolve(res)
            })
            // Catch errors if any
            .catch((err) => {
                return reject(err)
            });
    })
    return Result
}

export const GetAPI = async (
    path,
    data = {},
    headers = { Accept: 'application/json' }
) => {
    var result = await new Promise((resolve, reject) => {
        api.get(path, data, {
            headers: headers,
        })
            .then((response) => resolve(response.data))
            .catch((error) => {
                return reject(error.response);
            });
        // api.get;
    });
    return result;
};