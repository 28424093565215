import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import RouteURL from './Routes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './screens/Auth/Login';
import Home from './screens/dashboard/Home';
import Booking from './screens/dashboard/Booking';
import Delivery_person from './screens/dashboard/Delivery_person';
import Inventory from './screens/dashboard/Inventory';
import Profile from './screens/dashboard/Profile';
import AddInventory from './screens/dashboard/NewInventory';
import Dashboard from './screens/dashboard/Dashboard';



function App() {
  return (
    <>
     <BrowserRouter>
      <Routes>
        <Route path="/" Component={Login}></Route>
        <Route path="/Home" Component={Home}></Route>
        <Route path="/Dashboard" Component={Dashboard}></Route>
        <Route path="/Bookings" Component={Booking}></Route>
        <Route path="/Delivery-person" Component={Delivery_person}></Route>
        <Route path="/Inventory" Component={Inventory}></Route>
        <Route path="/AddInventory" Component={AddInventory}></Route>
        <Route path="/Profile" Component={Profile}></Route>
        

        
      </Routes>
    </BrowserRouter>
      {/* <RouteURL/> */}
    </>
  );
}

export default App;
