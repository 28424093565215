export const LOGINAPI = 'supervisor_login'
export const GETALLBOOKINGS = 'getAllBookings'
export const GETALL_DELIVERY_PERSONS = 'getAllDiliveryPerson'
export const GETALL_DELIVERY_PERSON_BYID = 'getDiliveryBoyBysupervisorId'
export const GET_INVENTORY = 'getAllInventory'
export const GETPROFILE = 'get_supervisor_by_id?supervisorId=1'
export const UPDATEPROFILE = 'update_supervisor_by_id'
export const AssignBooking = 'assignBookingToDiliveryBoy'






