import React, { useState, useEffect } from 'react';
import { Layout, Radio, Space, Table, Tag } from 'antd';
import '../css/home.css';
import HeaderSection from './Header';
import SideBar from './Sidebar';
import { GetAllBooking } from '../../Redux/actions/dashboard-actions';
import { connect } from 'react-redux';
import AssignModal from './assign';
// import type { ColumnsType } from 'antd/es/table';

const Booking = ({ ...props }) => {
  const [bottom, setBottom] = useState('bottomRight');
  const [AllBookingData, setAllBookingData] = useState([])
  const [Assign_modal, setAssign_modal] = useState(false)


  const WheelBookingscolumns = [
    {
      title: 'Date',
      dataIndex: 'booking_date',
      key: 'booking_date',
    },
    {
      title: 'Time',
      dataIndex: 'pickup_time',
      key: 'pickup_time',
    }, {
      title: 'Booking Id',
      dataIndex: 'booking_id',
      key: 'booking_id',
    },
    {
      title: 'Train No',
      dataIndex: 'booking_otp',
      key: 'booking_otp',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      //   render: (tags) => (
      //     <span>
      //       {tags.map((tag) => {
      //         let color = tag.length > 7 ? 'green' : 'geekblue';
      //         if (tag == 'Scheduled') {
      //           color = 'orange';
      //         }
      //         return (
      //           <Tag color={color} key={tag}>
      //             {tag.toUpperCase()}
      //           </Tag>
      //         );
      //       })}
      //     </span>
      //   ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a className="transparent-button" onClick={() => setAssign_modal({ visible: true, record: record })}>Assign {record.name}</a>
          {/* <a>Delete</a> */}
        </Space>
      ),
    },
  ];


  useEffect(() => {
    // console.log(props.GET_Bookings?.data)
    props.Get_Bookings().then((data) => {
      console.log(data)
      setAllBookingData(data?.data)
    })
  }, [])

  return (
    <>
      <HeaderSection />
      <Layout>
        <SideBar></SideBar>
        <Layout>

          <div style={{ width: '95%', margin: '0 auto' }}>
            <Radio.Group
              style={{ marginBottom: 10 }}
              options={{ label: 'bottomRight', value: 'bottomRight' }}
              value={bottom}
              onChange={(e) => {
                setBottom(e.target.value);
              }}
            />
            <p className='Home_title'>Wheel Chair Bookings</p>
            {/* <Table className='Content_block' columns={WheelBookingscolumns} pagination={{ position: [bottom] }} dataSource={props.GET_Bookings?.data} /> */}
            <Table className='Content_block' columns={WheelBookingscolumns} pagination={{ position: [bottom] }} dataSource={AllBookingData} />
          </div>
        </Layout>
        <AssignModal
          visible={Assign_modal.visible}
          record={Assign_modal.record}
          callbackBtn={() => setAssign_modal({ visible: false, record: null })}
        />
      </Layout>
    </>
  );
};


const mapStateToProps = (state) => ({
  // GET_Bookings: state.Dashboard_Store.WHEELCHAIR_BOOKING,
});

const mapDispatchToProps = (dispatch) => ({
  Get_Bookings: () => dispatch(GetAllBooking()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
