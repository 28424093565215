import React, { useState,useEffect } from 'react';
import { Layout,Radio, Space, Table, Tag } from 'antd';
import '../css/home.css';
import HeaderSection from './Header';
import SideBar from './Sidebar';
import { GetAll_Inventory } from '../../common/apiFunction';


const columns = [
  {
    title: 'Item Picture',
    dataIndex: 'url',
    key: 'url',
    render: (url) => (
      <span>
            <div className='inventory_img'>
                <img src={url}/>
            </div>
      </span>
    ),
    width: '30%',
  },
  {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
  }, {
    title: 'Quantity',
    dataIndex: 'folder_name',
    key: 'folder_name',
  },
];

// const data= [
//   {
//     key: '1',
//     Item_picture:['picture'],
//     name:'John Wick',
//     Quantity:'5 items',
//     // tags: ['nice', 'developer'],
//   },
//   {
//     key: '2',
//     Item_picture:['picture'],
//     name:'John Wick',
//     Quantity:'5 items',
//   },
//   {
//     key: '3',
//     Item_picture:['picture'],
//     name:'John Wick',
//     Quantity:'5 items',
//   },
//   {
//     key: '4',
//     Item_picture:['picture'],
//     name:'John Wick',
//     Quantity:'5 items',
//   },{
//     key: '5',
//     Item_picture:['picture'],
//     name:'John Wick',
//     Quantity:'5 items',
//   },{
//     key: '6',
//     Item_picture:['picture'],
//     name:'John Wick',
//     Quantity:'5 items',
//   },{
//     key: '7',
//     Item_picture:['picture'],
//     name:'John Wick',
//     Quantity:'5 items',
//   },{
//     key: '8',
//     Item_picture:['picture'],
//     name:'John Wick',
//     Quantity:'5 items',
//   },
// ];

const Inventory = () => {
  const [bottom, setBottom] = useState('bottomRight');
  const [Data, setData] = useState([])

  useEffect(() => {
    GetAll_Inventory().then((data) => {
      console.log(data)
      setData(data?.data)
    })
  }, [])


  return (
    <>
      <HeaderSection />
        <Layout>
        <SideBar></SideBar>
        <Layout>
    <div style={{width:'95%', margin: '0 auto'}}>
      <Radio.Group
        style={{ marginBottom: 10 }}
        options={{ label: 'bottomRight', value: 'bottomRight' }}
        value={bottom}
        onChange={(e) => {
          setBottom(e.target.value);
        }}
        />
        <p className='Home_title'>Inventory</p>
      <Table className='Content_block' columns={columns} pagination={{ position: [ bottom] }} dataSource={Data} />
    </div>
    </Layout>
    </Layout>

</>
  );
};

export default Inventory;