import React, { useState } from 'react';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BellOutlined, SettingOutlined,UserOutlined,VideoCameraOutlined,UploadOutlined,
  PlusOutlined,LogoutOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';
import { Header } from 'antd/es/layout/layout';
import '../css/home.css'
import {useNavigate} from "react-router-dom"
import '../css/home.css'
import Sider from 'antd/es/layout/Sider';



export default function HeaderSection() {
  const [collapsed, setCollapsed] = useState(false);
  const [pageURL, setPageURL] = useState(1)
  const navigate =  useNavigate();


  return (
    <>

      <Header className='Header-container d-flex justify-content-between'>
        <div className='d-flex ms-3'>
          {/* <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined className='headIcon' /> : <MenuFoldOutlined className='headIcon' />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              width: 64,
              height: 64,
            }}
          /> */}
          <div className='Logo_container'>
            <img src={require('../../assets/logo.png')} alt='Logo' />
          </div>
        </div>
        <div style={{ 'margin': '-8px 1%' }}>
          <BellOutlined className='headIcon' />
          <SettingOutlined className='headIcon ms-2' />
        </div>
      </Header>

    </>
  );
}