import React, { useEffect, useState } from 'react';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BellOutlined, SettingOutlined,UserOutlined,VideoCameraOutlined,UploadOutlined,
  PlusOutlined,LogoutOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Radio, Table, Tag } from 'antd';
import { Switch, Space } from 'antd';
import HeaderSection from './Header';
import SideBar from './Sidebar';
import Dashboard from './Dashboard';
import Booking from './Booking';
import Delivery_person from './Delivery_person';
import Inventory from './Inventory';
import { GET_PROFILE, GetAllBooking } from '../../common/apiFunction';
import Profile from './Profile';
import AddInventory from './NewInventory';
import {useNavigate } from "react-router-dom"


const { Header, Sider, Content } = Layout;


const BookingStatuscolumns = [
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  },
  {
    title: 'Booking Id',
    dataIndex: 'BookingId',
    key: 'BookingId',
  },

  {
    title: 'Status',
    key: 'Status',
    dataIndex: 'Status',
    render: (tags) => (
      <span>
        {tags.map((tag) => {
          let color = tag.length > 7 ? 'green' : 'geekblue';
          if (tag == 'Scheduled') {
            color = 'orange';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  }
];

const AvailableUsercolumns = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'Status',
    key: 'Status',
    dataIndex: 'Status',
    render: (tags) => (
      <span>
        {tags.map((tag) => {

          let isChecked = tag === 'active';
          return (
            <Space direction="vertical">
              <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={isChecked} />
            </Space>
          );
        })}
      </span>
    ),
  },
];

const WheelBookingscolumns = [
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
  },
  {
    title: 'Time',
    dataIndex: 'Time',
    key: 'Time',
  }, {
    title: 'Booking Id',
    dataIndex: 'BookingId',
    key: 'BookingId',
  },
  {
    title: 'Train No',
    dataIndex: 'TrainNo',
    key: 'TrainNo',
  },
  {
    title: 'Status',
    key: 'Status',
    dataIndex: 'Status',
    render: (tags) => (
      <span>
        {tags.map((tag) => {
          let color = tag.length > 7 ? 'green' : 'geekblue';
          if (tag == 'Scheduled') {
            color = 'orange';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <a className="transparent-button">Assign {record.name}</a>
  //       {/* <a>Delete</a> */}
  //     </Space>
  //   ),
  // },
];

const BookingStatusdata = [
  {
    key: '1',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  },
  {
    key: '2',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  },
  {
    key: '3',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  },
  {
    key: '4',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  }, {
    key: '5',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  }, {
    key: '6',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  }, {
    key: '7',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  }, {
    key: '8',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  },
];

const AvailableUserdata = [
  {
    key: '1',
    user: 'Joh Wick',
    Status: ['active']
  },
  {
    key: '2',
    user: 'Joh Wick',
    Status: ['inactive']
  },
  {
    key: '3',
    user: 'Joh Wick',
    Status: ['inactive']
  },
  {
    key: '4',
    user: 'Joh Wick',
    Status: ['inactive']
  }, {
    key: '5',
    user: 'Joh Wick',
    Status: ['inactive']
  }, {
    key: '6',
    user: 'Joh Wick',
    Status: ['active']
  }, {
    key: '7',
    user: 'Joh Wick',
    Status: ['inactive']
  }, {
    key: '8',
    user: 'Joh Wick',
    Status: ['inactive']
  },
];

const WheelBookingdata = [
  {
    key: '1',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Ongoing']
    // tags: ['nice', 'developer'],
  },
  {
    key: '2',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Completed']
  },
  {
    key: '3',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Scheduled']
  },
  {
    key: '4',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Ongoing']
  }, {
    key: '5',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Completed']
  }, {
    key: '6',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Scheduled']
  }, {
    key: '7',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Ongoing']
  }, {
    key: '8',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Completed']
  },
];


const Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [bottom, setBottom] = useState('bottomRight');
  const [pageURL, setPageURL] = useState(1)
  const [ProfileData,setProfileData] = useState([])
  const navigate =  useNavigate();

  const handleMenu = ((Keyname) => {
    let keyId = Keyname.key;
    // console.log(keyId);
    setPageURL(keyId)
  })

  useEffect(() => {
    GET_PROFILE().then((data) => {
      console.log(data)
      setProfileData(data?.data?.data[0])
    })
  }, [])


  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));



  return (
    <>
      <div className='Main-body'>
      <HeaderSection />
      <Layout className='Aside'>
        <Sider className='sideBar' trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            mode="inline"
            style={{ 'borderRadius': '12px', 'background': '#202020' }}
            defaultSelectedKeys={['1']}
            onSelect={(key) => handleMenu(key)}
            items={[
              {
                key: '1',
                icon: <UserOutlined />,
                label: 'Dashboard',
              },
              {
                key: '2',
                icon: <VideoCameraOutlined />,
                label: 'Bookings',
              },
              {
                key: '3',
                icon: <UploadOutlined />,
                label: 'Delivery Person',
              },
              {
                key: '4',
                icon: <UserOutlined />,
                label: 'Inventory',
              },
            ]}
          />

          <div className='profile_container position-absolute bottom-0'>
            {
              collapsed ? <Button style={{ 'background': '#FEE25B', 'color': '#000' }} icon={<PlusOutlined />}>
              </Button> :
                <Button type="primary" style={{ 'background': '#FEE25B', 'color': '#000' }}
                onClick={() => setPageURL(5)}
                icon={<PlusOutlined />} size={'large'}>
                  Add Item
                </Button>
            }
            {
              collapsed ? '' :
                <div className='Profile'
                 onClick={() => setPageURL(6)}
                 >
                  <div className='avatar'>
                    <img src={require('../../assets/profile_avatar.png')} alt='profile image' />
                  </div>
                  <p>{ProfileData?.name}</p>
                  <p><i>{ProfileData?.email}</i></p>
                </div>
            }
            {
              collapsed ? <Button danger icon={<LogoutOutlined />}></Button> :
                <Button danger icon={<LogoutOutlined />} size={'large'} onClick={()=>navigate('/')}>
                  Log Out
                </Button>
            }
          </div>

        </Sider>
        {/* <Layout>
          {
            pageURL == 1 ? <Dashboard/> 
            : pageURL == 2 ? <Booking/> 
            : pageURL == 3 ? <Delivery_person/>
            : pageURL == 4 ? <Inventory/> 
            : pageURL == 5 ? <AddInventory/> 
            : pageURL == 6 ? <Profile/> 
            : ''
          }

        </Layout> */}
      </Layout>

      </div>

    </>
  );
};

export default Home;