// src/reducers/index.js
import { combineReducers } from 'redux';
import { DashboardReducer } from './dashboard-reducer';

const rootReducer = combineReducers({
            Dashboard_Store: DashboardReducer,
  // Add more reducers here if needed
});

export default rootReducer;
