import React, { useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Radio, Table, Tag } from 'antd';
import { Switch, Space } from 'antd';
import { GetAll_Delievery_Guys } from '../../common/apiFunction';
import { GetAllBooking } from '../../Redux/actions/dashboard-actions';
import { connect } from 'react-redux';
import SideBar from './Sidebar';
import HeaderSection from './Header';
import { useNavigate } from 'react-router-dom';
import AssignModal from './assign';

const { Content } = Layout;

const BookingStatuscolumns = [
  {
    title: 'Date',
    dataIndex: 'booking_date',
    key: 'booking_date',
  },
  {
    title: 'Booking Id',
    dataIndex: 'booking_id',
    key: 'booking_id',
  },

  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    // render: (tags) => (
    //   <span>
    //     {tags.map((tag) => {
    //       let color = tag.length > 7 ? 'green' : 'geekblue';
    //       if (tag == 'Scheduled') {
    //         color = 'orange';
    //       }
    //       return (
    //         <Tag color={color} key={tag}>
    //           {tag.toUpperCase()}
    //         </Tag>
    //       );
    //     })}
    //   </span>
    // ),
  }
];

const AvailableUsercolumns = [
  {
    title: 'User',
    dataIndex: 'dilverypartner_name',
    key: 'dilverypartner_name',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    // render: (tags) => (
    //   <span>
    //     {tags.map((tag) => {

    //       let isChecked = tag === 'active';
    //       return (
    //         <Space direction="vertical">
    //           <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={isChecked} />
    //         </Space>
    //       );
    //     })}
    //   </span>
    // ),
  },
];



const BookingStatusdata = [
  {
    key: '1',
    Date: '12/09/2022',
    BookingId: 'WHL89790',
    Status: ['Ongoing']
  }
];

const WheelBookingdata = [
  {
    key: '1',
    Date: '12/09/2022',
    Time: '09:20',
    BookingId: 'WHL89790',
    TrainNo: '895325',
    Status: ['Ongoing']
    // tags: ['nice', 'developer'],
  },

];


const Dashboard = ({ ...props }) => {
  const [bottom, setBottom] = useState('bottomRight');
  const [AllBookingData, setAllBookingData] = useState([])
  const [AvailableDeleiveryGuys, SetAvailableDeleiveryGuys] = useState([])
  const [selectedStatus, setSelectedStatus] = useState('');
  const [activeButton, setActiveButton] = useState(''); // Initially, no button is active
  const [Assign_modal, setAssign_modal] = useState(false)

  const navigate = useNavigate()

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const WheelBookingscolumns = [
    {
      title: 'Date',
      dataIndex: 'booking_date',
      key: 'booking_date',
    },
    {
      title: 'Time',
      dataIndex: 'pickup_time',
      key: 'pickup_time',
    }, {
      title: 'Booking Id',
      dataIndex: 'booking_id',
      key: 'booking_id',
    },
    {
      title: 'Train No',
      dataIndex: 'booking_otp',
      key: 'booking_otp',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      //   render: (tags) => (
      //     <span>
      //       {tags.map((tag) => {
      //         let color = tag.length > 7 ? 'green' : 'geekblue';
      //         if (tag == 'Scheduled') {
      //           color = 'orange';
      //         }
      //         return (
      //           <Tag color={color} key={tag}>
      //             {tag.toUpperCase()}
      //           </Tag>
      //         );
      //       })}
      //     </span>
      //   ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a className="transparent-button" onClick={() => setAssign_modal({ visible: true, record: record })}>Assign {record.name}</a>
          {/* <a>Delete</a> */}
        </Space>
      ),
    },
  ];


  useEffect(() => {
    props.Get_Bookings().then((data) => {
      console.log(data)
      setAllBookingData(data?.data)
    })
    GetAll_Delievery_Guys().then((data) => {
      console.log(data)
      SetAvailableDeleiveryGuys(data?.data)
    })
  }, [])


  return (
    <>
      <HeaderSection />
      <Layout>
        <SideBar></SideBar>
        <Layout>
          <Content
            style={{
              margin: "27px 16px 16px 5px",
              // padding: 24,
              minHeight: 280,
              // background: colorBgContainer,
            }}
          >
            <Grid container spacing={2} columns={16}>
              <Grid className='dashboard_grid' xs={7.7} style={{ 'marginLeft': '25px' }}>
                <div className='d-flex mb-3'>
                  <p className='flex-grow-1 dashbaord_heading'>Booking Status</p>
                  {AllBookingData ? <Space wrap>
                    <Button
                      style={{
                        backgroundColor: activeButton === 'ongoing' ? 'rgb(254, 226, 91)' : 'transparent',
                        color: activeButton === 'ongoing' ? '#000' : '#000', // Change text color if needed
                      }}
                      size="large"
                      onClick={() => {
                        setSelectedStatus('ongoing');
                        setActiveButton('ongoing');
                      }}
                      disabled={activeButton === 'ongoing'}
                    >
                      Ongoing
                    </Button>
                    <Button
                      style={{
                        backgroundColor: activeButton === 'completed' ? 'rgb(254, 226, 91)' : 'transparent',
                        color: activeButton === 'completed' ? '#000' : '#000', // Change text color if needed
                      }}
                      size="large"
                      onClick={() => {
                        setSelectedStatus('completed');
                        setActiveButton('completed');
                      }}
                      disabled={activeButton === 'completed'}
                    >
                      Completed
                    </Button>
                    <Button
                      style={{
                        backgroundColor: activeButton === 'scheduled' ? 'rgb(254, 226, 91)' : 'transparent',
                        color: activeButton === 'scheduled' ? '#000' : '#000', // Change text color if needed
                      }}
                      size="large"
                      onClick={() => {
                        setSelectedStatus('scheduled');
                        setActiveButton('scheduled');
                      }}
                      disabled={activeButton === 'scheduled'}
                    >
                      Scheduled
                    </Button>


                  </Space> : ''}
                </div>
                <Table className='dashboard_block' columns={BookingStatuscolumns} pagination={{ position: [bottom] }}
                  dataSource={AllBookingData?.filter((item) => {
                    // Filter based on selectedStatus
                    console.log(selectedStatus)
                    if (selectedStatus === 'ongoing') {
                      return item.status === 'ongoing';
                    } else if (selectedStatus === 'completed') {
                      return item.status === 'completed';
                    } else if (selectedStatus === 'scheduled') {
                      return item.status === 'scheduled';
                    }
                    // If no status is selected, show all data
                    return item;
                  })} />
                <Space className="d-flex justify-content-center mt-2 mb-2">
                  {AllBookingData ? <Button size={'Large'} onClick={() => navigate('/bookings')} >View All</Button> : ''}
                </Space>
              </Grid>
              <Grid className='dashboard_grid' xs={7.4}>
                <div className='d-flex mb-3'>
                  <p className='flex-grow-1 dashbaord_heading'>Available</p>
                  <Space wrap>
                    {AvailableDeleiveryGuys ? <Button size={'Large'} >View All</Button> : ''}
                  </Space>
                </div>
                <Table className='dashboard_block' columns={AvailableUsercolumns} pagination={{ position: [bottom] }} dataSource={AvailableDeleiveryGuys} />

              </Grid>
            </Grid>
            <Grid container spacing={1} columns={16} style={{ 'margin': '8px 0px' }}>
              <Grid className='dashboard_grid' xs={16}>
                <div className='d-flex mb-3'>
                  <p className='flex-grow-1 dashbaord_heading'>Wheelchair Bookings</p>
                  <Space wrap>
                    {AllBookingData ? <Button size={'Large'} onClick={() => navigate('/bookings')}>View All</Button> : ''}
                  </Space>
                </div>
                <Table className='dashboard_block' style={{ 'height': '33vh' }} columns={WheelBookingscolumns} pagination={{ position: [bottom] }} dataSource={AllBookingData} />

              </Grid>

            </Grid>
          </Content>
          <AssignModal
            visible={Assign_modal.visible}
            record={Assign_modal.record}
            callbackBtn={() => setAssign_modal({ visible: false, record: null })}
          />
        </Layout>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  // Wishlist_AddData: state.wishlist_store.additem_in_wishlist,
});

const mapDispatchToProps = (dispatch) => ({
  Get_Bookings: () => dispatch(GetAllBooking()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
