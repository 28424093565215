import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import EmailIcon from '@mui/icons-material/Email';
import '../css/auth.css';
import { Authentication } from '../../common/apiFunction';
import { useNavigate } from "react-router-dom"
import { message} from 'antd';



export default function Login() {
    const [LoginDetails, setLoginDetails] = React.useState([])

    const [showPassword, setShowPassword] = React.useState(false);
    const navigate = useNavigate();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleCredential = (name, e) => {
        console.log(name)
        console.log(e.target.value)
        setLoginDetails((data) => ({ ...data, [name]: e.target.value }))
    }

    React.useEffect(() => {
        console.log(LoginDetails)
    }, [LoginDetails])


    const handleLogin = () => {
        console.log(LoginDetails)
            if (LoginDetails?.email === '' || LoginDetails?.email === undefined) {
                message.error('Enter mail Id')
            }
            else if (LoginDetails?.password === '' || LoginDetails?.password === undefined) {
                message.error('Enter password')
            }
            else {
                console.log(LoginDetails)
                Authentication(LoginDetails)
                navigate('/dashboard')
            }

    }

    return (
        <div className='Main-container'>
            <div className='Main-container-inner'>
                <div className='Logo-container'>
                    <img src={require("../../assets/logo.png")} alt='Logo' />
                </div>
                <div className='Login-container'>
                    <h5 className='ms-2'>Login in To Your Account</h5>
                    <Box
                        component="form"
                        sx={{ '& > :not(style)': { m: 1 }, }}
                        noValidate
                        autoComplete="off"
                    >

                        <FormControl className='Form-control' sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="standard-adornment-email">Email or username</InputLabel>
                            <Input
                                id="standard-adornment-email"
                                type='text'
                                onChange={(text) => handleCredential('email', text)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <EmailIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <br></br>

                        <FormControl className='Form-control' sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) => handleCredential('password', e)}

                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Box>
                    <FormControlLabel className='fs-1' control={<Checkbox defaultChecked />} label="Remember Me" />
                    <br></br>
                    <Button className='Loginbtn'
                        onClick={handleLogin}
                        variant="contained" endIcon={<SendIcon />}>
                        Login
                    </Button>
                </div>
            </div>
        </div>

    );
}
