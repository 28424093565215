// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main-container {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.Main-container-inner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    border: 1px solid #979797;
    border-radius: 15px;
    width: 55%;
}
.Logo-container{
    background-color: #F7F7F7;
}
.Logo-container img{
    width: 45%;
    border-radius: 20px;
}
.Login-container{
    text-align: left;
    width: 55%;
}
.Loginbtn{
    width: 100%;
    font-weight: bold;
    margin-top: 8% !important;
    background: #FEE25B !important;
    color: black !important;
    border-radius: 7px !important;

}
.Form-control{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/screens/css/auth.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,UAAU;AACd;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,UAAU;IACV,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,UAAU;AACd;AACA;IACI,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,8BAA8B;IAC9B,uBAAuB;IACvB,6BAA6B;;AAEjC;AACA;IACI,WAAW;AACf","sourcesContent":[".Main-container {\n    display: flex;\n    height: 100vh;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n}\n.Main-container-inner {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    padding: 20px;\n    border: 1px solid #979797;\n    border-radius: 15px;\n    width: 55%;\n}\n.Logo-container{\n    background-color: #F7F7F7;\n}\n.Logo-container img{\n    width: 45%;\n    border-radius: 20px;\n}\n.Login-container{\n    text-align: left;\n    width: 55%;\n}\n.Loginbtn{\n    width: 100%;\n    font-weight: bold;\n    margin-top: 8% !important;\n    background: #FEE25B !important;\n    color: black !important;\n    border-radius: 7px !important;\n\n}\n.Form-control{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
