import React, { useEffect, useState } from 'react';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BellOutlined, SettingOutlined,UserOutlined,VideoCameraOutlined,UploadOutlined,
  PlusOutlined,LogoutOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';
import { Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import {useNavigate, useSearchParams,useParams } from "react-router-dom"
import '../css/home.css'
import Dashboard from './Dashboard';
import Booking from './Booking';
import Delivery_person from './Delivery_person';
import Inventory from './Inventory';
import { GET_PROFILE } from '../../common/apiFunction';
  

export default function SideBar() {
  const [collapsed, setCollapsed] = useState(false);
  const [pageURL, setPageURL] = useState(1)
  const [SelectedMenu,setSelectedMenu] =  useState([])
  const [ProfileData,setProfileData] = useState([])

  const navigate =  useNavigate();

  useEffect(()=>{
    const pathname = window.location.pathname;
    const valueAfterSlash = pathname.substring(1); // Remove the leading slash
     if(valueAfterSlash === 'bookings')
    {
      setSelectedMenu(['2'])
    }
    else if(valueAfterSlash === 'Delivery-person')
    {
      setSelectedMenu(['3'])
    }
    else if(valueAfterSlash === 'Inventory')
    {
      setSelectedMenu(['4'])
    } else if(valueAfterSlash === 'AddInventory')
    {
      setSelectedMenu(['5'])
    }
    else{
      setSelectedMenu(['1'])
    }

    GET_PROFILE().then((data) => {
      console.log(data)
      setProfileData(data?.data)
    })

  },[])

  const handleMenu = ((Keyname) => {
    let keyId = Number(Keyname.key);
    if(keyId === 1) navigate('/dashboard')
    if(keyId === 2) navigate('/bookings')
    if(keyId === 3) navigate('/Delivery-person')
    if(keyId === 4) navigate('/Inventory')
    if(keyId === 5) navigate('/AddInventory')

    setPageURL(Keyname)
  })

 console.log(SelectedMenu)
 console.log(typeof(SelectedMenu))


  return (
    <>
    {/* <Layout className='Aside'> */}
    <Sider className='sideBar' trigger={null} collapsible collapsed={collapsed}>
      <div className="demo-logo-vertical" />
      {SelectedMenu.length != 0 ?<Menu
        theme="dark"
        mode="inline"
        style={{ 'borderRadius': '12px', 'background': '#202020' }}
        defaultSelectedKeys={SelectedMenu}
        onSelect={(key) => handleMenu(key)}
        items={[
          {
            key: '1',
            icon: <UserOutlined />,
            label: 'Dashboard',
          },
          {
            key: '2',
            icon: <VideoCameraOutlined />,
            label: 'Bookings',
          },
          {
            key: '3',
            icon: <UploadOutlined />,
            label: 'Delivery Person',
          },
          {
            key: '4',
            icon: <UserOutlined />,
            label: 'Inventory',
          },
        ]}
      />:''}

      <div className='profile_container position-absolute bottom-0'>
        {
          collapsed ? <Button style={{ 'background': '#FEE25B', 'color': '#000' }} icon={<PlusOutlined />}>
          </Button> :
            <Button type="primary" style={{ 'background': '#FEE25B', 'color': '#000' }} icon={<PlusOutlined />} size={'large'}
            onClick={() => navigate('/AddInventory')}
            >
              Add Item
            </Button>
        }
        {
          collapsed ? '' :
            <div className='Profile' onClick={()=>navigate('/Profile')}>
              <div className='avatar'>
                <img src={require('../../assets/profile_avatar.png')} alt='profile image' />
              </div>
              <p>{ProfileData?.name}</p>
                  <p><i>{ProfileData?.email}</i></p>
            </div>
        }
        {
          collapsed ? <Button danger icon={<LogoutOutlined />}></Button> :
            <Button danger icon={<LogoutOutlined />} size={'large'} onClick={()=>navigate('/')}>
              Log Out
            </Button>
        }
      </div>

    </Sider>
        
                {/* </Layout> */}
                </>
  );
}