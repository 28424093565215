import React, { useState } from 'react';
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import {
    InputNumber, Button, Checkbox, Form, Input, Layout, Radio, Space, Table, Tag, Upload,
} from 'antd';
import '../css/home.css';
import SideBar from './Sidebar';
import { EditOutlined, InboxOutlined } from '@mui/icons-material';
import HeaderSection from './Header';
const { Content } = Layout;

// import type { ColumnsType } from 'antd/es/table';


const AddInventory = () => {
    const [bottom, setBottom] = useState('bottomRight');

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    return (

        <>
           <div className='Main-body'>
      <HeaderSection />
            <Layout>
                <SideBar></SideBar>
                <Layout>
                    <Content
                        style={{
                            margin: "18px 16px 16px 10px",
                            padding: 24,
                            minHeight: 280,
                            borderRadius: '10px',
                            background: '#fff',
                        }}
                    >
                        <h4 className='ms-4'>Add Item</h4>

                        <div className='d-flex justify-space-betwen'>
                            <div className='Profile-section'>
                                <Form
                                    name="normal_login"
                                    className="login-form mt-2"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                >

                                    <label className='mb-2'>Item Name</label>
                                    <Form.Item
                                        name="Item Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your item name!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<EditOutlined className="site-form-item-icon" />}
                                            type="text"
                                            placeholder="Item Name"
                                        />
                                    </Form.Item>

                                    <label className='mb-2'>Upload Item Picture</label>
                                    <Form.Item>
                                        <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                            <Upload.Dragger name="files" action="/upload.do">
                                                <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                </p>
                                                <p className="ant-upload-text">Browse or drag picture to upload</p>
                                                <p className="ant-upload-hint">Maximum Size Upto 5MB.</p>
                                            </Upload.Dragger>
                                        </Form.Item>
                                    </Form.Item>


                                    <Form.Item className="Quantity" label="Quantity">

                                        <span className='ms-2' style={{ 'cursor': 'pointer' }}> + </span>
                                        <span className='QuantityAmt'> {10} </span>
                                        <span style={{ 'cursor': 'pointer' }}> - </span>

                                    </Form.Item>


                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-form-button">
                                            Add Item
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="Inventory-dummy ms-4" >
                                <img src={require('../../assets/new_item.png')} />
                            </div>
                        </div>
                    </Content>
                </Layout>
            </Layout>
            </div>
        </>

    );
};

export default AddInventory;