import React, { useState, useEffect } from 'react';
import { Layout, Radio, Space, Table, Tag } from 'antd';
import '../css/home.css';
// import type { ColumnsType } from 'antd/es/table';
import HeaderSection from './Header';
import SideBar from './Sidebar';
import { GetAll_Delievery_Persons } from '../../common/apiFunction';


const columns = [
  // {
  //   title: 'Date',
  //   dataIndex: 'Date',
  //   key: 'Date',
  //   render: (text) => <a>{text}</a>,
  // },
  {
    title: 'Employee ID',
    dataIndex: 'dilivery_id',
    key: 'dilivery_id',
  },
  {
    title: 'Name',
    dataIndex: 'dilverypartner_name',
    key: 'dilverypartner_name',
  }, {
    title: 'In Time',
    dataIndex: 'sync_date',
    key: 'sync_date',
  },
  {
    title: 'Out Time',
    dataIndex: 'sync_date',
    key: 'sync_date',
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status) => (
      <span>
        {/* {tags.map((tag) => {
        
          // let color = tag.length > 7 ? 'green' : 'geekblue';
        // let color = 'gray';

        // if (tag == 'Active') {
          // color = 'green';
          // }
         return ( */}
        {/* <Tag color={color} key={status}> */}
        <Tag key={status}>
          {status ===  1 ?'Active':'Inactive'}
        </Tag>
        {/* );
        })} */}
      {/* // } */}
      </span>
    ),
  },
];


const Delivery_person = () => {
  const [bottom, setBottom] = useState('bottomRight');
  const [Data, setData] = useState([])

  useEffect(() => {
    GetAll_Delievery_Persons().then((data) => {
      console.log(data)
      setData(data?.data)
    })
  }, [])



  return (
    <>
      <HeaderSection />
      <Layout>
        <SideBar></SideBar>
        <Layout>
        <div style={{ width: '95%', margin: '0 auto' }}>
          <Radio.Group
            style={{ marginBottom: 10 }}
            options={{ label: 'bottomRight', value: 'bottomRight' }}
            value={bottom}
            onChange={(e) => {
              setBottom(e.target.value);
            }}
          />
          <p className='Home_title'>Delivery Person</p>
          <Table className='Content_block' columns={columns} pagination={{ position: [bottom] }} dataSource={Data} />
        </div>
      </Layout>
      </Layout>
    </>

  );
};

export default Delivery_person;