import React, { useEffect, useState } from 'react';
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, Form, Input, Layout, Radio, Space, Table, Tag, Upload, message,
} from 'antd';
import '../css/home.css';
import SideBar from './Sidebar';
import { GET_PROFILE, Update_Profile } from '../../common/apiFunction';
import HeaderSection from './Header';
const { Content } = Layout;

// import type { ColumnsType } from 'antd/es/table';


const Profile = () => {
  const [bottom, setBottom] = useState('bottomRight');
  const [Data, setData] = useState([])
  const [UpdatedData, setUpdatedData] = useState([])
  const [SelectedImage,setSelectedImage] = useState('')

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  const initialValues = {
    email: Data?.email || '',
    username: Data?.name || '',
    phone: Data?.phone || '',
  };


  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    console.log(e.fileList)
    return e?.fileList;
  };

  const handleImageUpload = (info) => {
    console.log(info)
    // if (info.file.status === 'done') {
    console.log('Image uploaded:', info.file.originFileObj);
    setSelectedImage(info.file.originFileObj);
    // }
  };


  useEffect(() => {
    GET_PROFILE().then((data) => {
      console.log(data)
      setData(data?.data)
      setUpdatedData({
        "name": data?.data?.name,
        "email": data?.data?.email,
        "phone": data?.data?.phone
      })
    })
  }, [])


  const HandleUpdate = (() => {
    console.log(UpdatedData)
    if (UpdatedData.name === undefined || UpdatedData.name === '') {
      alert('Update Name')
    } else if (UpdatedData.email === undefined || UpdatedData.email === '') {
      alert('Update Email')
    } else if (UpdatedData.phone === undefined || UpdatedData.phone === '') {
      alert('Update Phone Number')
    }
    else {
      if(SelectedImage != '')
      {
        UpdatedData["supervisorImage"] = SelectedImage
      }
      Update_Profile(UpdatedData).then((data) => {
        console.log(data)
        if (data?.status == true) {
          alert("data updated successfully")
        }
      })
    }
  })

  const handleInput = ((name, e) => {
    setUpdatedData((prev) => ({ ...prev, [name]: e.target.value }))
  })

  return (

    <>
      {/* <div className='Main-body'> */}
      <HeaderSection />
      <Layout>
        <SideBar></SideBar>
        <Layout>
          <Content
            style={{
              margin: "18px 16px 16px 10px",
              padding: 24,
              minHeight: 280,
              borderRadius: '10px',
              background: '#fff',
            }}
          >
            <h4 className='ms-4'>Profile</h4>

            <div className='d-flex'>
              <div className='Profile-section'>

                <div className='d-flex'>
                  <div className='Profile-Img'>
                    <img src={require('../../assets/profile_avatar.png')} alt='Avatar' />
                  </div>
                  <div className='ms-4'>
                    <Upload name="logo" listType="picture" onChange={handleImageUpload}>
                      <Button className='h-25 p-2' >Choose Profile Picture</Button>
                    </Upload>

                    <Button danger size={'large'} className='m-2 mt-3'>
                      Remove Picture
                    </Button>
                  </div>
                </div>
                {Data?.length != 0 ? <Form
                  name="normal_login"
                  className="login-form mt-4"
                  initialValues={initialValues}
                  onFinish={onFinish}
                >
                  <label className='mb-2'>Name</label>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your name!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      onChange={(e) => handleInput('name', e)}
                      placeholder="Christina Sea" />
                  </Form.Item>
                  <label className='mb-2'>Email Address</label>

                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<MailOutlined className="site-form-item-icon" />}
                      type="email"
                      onChange={(e) => handleInput('email', e)}
                      placeholder="christina.sea@hotmail.com"
                    />
                  </Form.Item>

                  <label className='mb-2'>Phone No.</label>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone!',
                      },
                    ]}
                  >
                    <Input
                      prefix={<PhoneOutlined className="site-form-item-icon" />}
                      type="text"
                      onChange={(e) => handleInput('phone', e)}
                      placeholder="+91 1234567890"
                    />
                  </Form.Item>


                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" onClick={HandleUpdate}>
                      Update
                    </Button>
                  </Form.Item>
                </Form> : <p className='mt-2'>Profile data Loading </p>}
              </div>
              <div className="Profile-dummy ms-4" >
                <img src={require('../../assets/profile.png')} />
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>

      {/* </div>    */}
    </>

  );
};

export default Profile;