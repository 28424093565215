import { GetAPI } from "../../common/apiMethod";
import { GETALLBOOKINGS } from "../../constant/api_end"
import { ALL_BOOKINGS } from "./action-types";

// export const GetAllBooking = (async(dispatch,getState)=>{

//     // return GetAPI(GETALLBOOKINGS).then((data)=>{
//     return GetAPI('https://dummyjson.com/products/1').then((data)=>{
//         console.log(data)
//         dispatch({ type: ALL_BOOKINGS, payload: data });
//         return data
//     })
//     .catch((error)=>{
//         console.log(error)
//         return error
//     })
// })


export const GetAllBooking = () => async (dispatch,getState) => {
    try {
        const data = await GetAPI(GETALLBOOKINGS);
        dispatch({ type: ALL_BOOKINGS, payload: data });
        return data
    } catch (err) {
        return console.log(err);
    }
};
