import { AssignBooking, GETALLBOOKINGS, GETALL_DELIVERY_PERSONS, GETALL_DELIVERY_PERSON_BYID, GETPROFILE, GET_INVENTORY, LOGINAPI, UPDATEPROFILE } from "../constant/api_end"
import { GetAPI, PostAPI } from "./apiMethod"

export const Authentication = (async(RequestedData)=>{
    return PostAPI(LOGINAPI,RequestedData).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        return error
    })
})

// export const GetAllBooking = (async()=>{
//     return GetAPI(GETALLBOOKINGS).then((data)=>{
//         console.log(data)
//         return data
//     })
//     .catch((error)=>{
//         console.log(error)
//         return error
    
//     })
// })

export const GetAll_Delievery_Guys = (async()=>{
    return GetAPI(GETALL_DELIVERY_PERSONS).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        return error
    
    })
})

export const GetAll_Delievery_Persons = (async()=>{
    return GetAPI(GETALL_DELIVERY_PERSON_BYID+'?supervisorId=test').then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        return error
    
    })
})

export const GetAll_Inventory = (async()=>{
    return GetAPI(GET_INVENTORY).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        return error
    
    })
})

export const GET_PROFILE = (async()=>{
    return GetAPI(GETPROFILE).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        return error
    
    })
})

export const Update_Profile = (async(RequestedData)=>{
    const formData = new FormData()
    if(RequestedData != undefined)
    {
        formData.append('supervisorImage',RequestedData?.supervisorImage)
    }
    formData.append('name',RequestedData?.name)
    formData.append('email',RequestedData?.email)
    formData.append('phone',RequestedData?.phone)

    
    return PostAPI(UPDATEPROFILE,formData).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        return error
    })
})

export const AssignBooking_toDeliveryGuy = (async(RequestedData)=>{
    return PostAPI(AssignBooking,RequestedData).then((data)=>{
        console.log(data)
        return data
    })
    .catch((error)=>{
        console.log(error)
        return error
    })
})



